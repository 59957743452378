<template>
	<div class="navmb">
		<div class="flex flexa">
			<el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()">
				<Back />
			</el-icon> &nbsp; &nbsp;
			<span class="">Bug详情</span>
		</div>
	</div>
	<div class="jl-about1">
		<div class="titbox">
			<div class="topbox">
				<div class="tit">
					Bug标题：{{ info.bugTitle }}
				</div>
				<div class="upDate">
					<div class="p">上次更新时间: {{ prevUpdateTime }}</div>
					<el-button @click="getData" :disabled="loading">
						<template #icon>
							<el-icon :class="{ 'is-loading': loading }">
								<img style="width: 1em;height:1em" draggable="false"
									src="../../assets/imgs/refersh.png" />
							</el-icon>
						</template>
					</el-button>
				</div>
			</div>
			<div class="descbox">
				<div class="tag">
					<div class="con">
						<div class="lab">当前Bug状态：</div>
						<div class="type">{{ bugStatus.get(info.bugStatus) }}</div>
					</div>
					<div class="con">
						<div class="lab">严重等级：</div>
						<div class="severity">
							<i :style="`background-color: ${priorityC[info.priority]}`"></i>
							<div class="p">{{ priorityL[info.priority] }}</div>
						</div>
					</div>
					<!-- <div class="con">
						<div class="lab">Bug来源：</div>
						<div class="p">{{ info.source }}</div>
					</div> -->
				</div>
				<div class="operation">
					<el-button @click="clickDelete">删除</el-button>
					<!-- <el-button type="primary"
						@click="$router.push({ path: '/bug/create', state: { id: info.id } })">编辑</el-button> -->
					<!-- <a href="" class="btn nopass">
						<div class="i">
							<img src="../../assets/imgs/nopass.png" alt="" />
						</div>
						<div class="p">不通过审核</div>
					</a>
					<a href="" class="btn pass">
						<div class="i"><img src="../../assets/imgs/pass.png" alt="" /></div>
						<div class="p">通过审核</div>
					</a> -->
				</div>
			</div>
		</div>
		<div class="infobox">
			<div class="lbox">
				<div class="box1">
					<div class="g-tit1">
						<div class="i"></div>
						<div class="p">Bug详细描述</div>
					</div>
					<div class="scrollbox">
						<v-md-preview :text="info.bugContent"></v-md-preview>
						<!-- <div class="copybox">
						</div> -->
					</div>
				</div>
				<div class="box1">
					<div class="g-tit1">
						<div class="i"></div>
						<div class="p">Bug附件</div>
					</div>
					<div class="scrollbox">
						<el-link v-for="(item, index) in attachmentPath" :key="index" class="lh30 block"
							:underline="false" type="primary" @click="clickXz(item, item)">{{ item }}</el-link>
					</div>
				</div>
				<div class="box2">
					<div class="g-tit2">
						<div class="l">
							<div class="i"></div>
							<div class="tit">Bug补充描述</div>
							<el-button v-if="info.bugStatus != 3" type="primary"
								@click="record.visible = true">提交反馈</el-button>
						</div>
						<div class="r">
							<div class="lab">时间排序：</div>
							<div class="sel">
								<el-select v-model="record.param.sort" placeholder="请选择"
									@change="bugCommunicationRecord(1)">
									<el-option label="按照时间正序" :value="1" />
									<el-option label="按照时间倒序" :value="2" />
								</el-select>
							</div>
						</div>
					</div>
					<ul class="ul-list">
						<template v-for="(item, index) in record.list">
							<li v-if="item.feedback == 1" :key="index + 'c'">
								<div class="pic">
									<img :src="item.headPic" alt="" />
								</div>
								<div class="info">
									<div class="name">{{ item.username }}</div>
									<div class="desc" v-html="item.bugContent"></div>
									<div class="bottom">
										<div class="time">{{ item.createTime }}</div>
										<div class="btns">
											<div class="btn"
												@click="record.visible = true; record.form.bugContent = item.bugContent, record.form.id = item.id">
												<div class="i">
													<img src="../../assets/imgs/edit2.png" alt="" />
												</div>
												<div class="p">编辑</div>
											</div>
											<div @click="clickDeletes(item.id)" class="btn">
												<div class="i">
													<img src="../../assets/imgs/del2.png" alt="" />
												</div>
												<div class="p">删除</div>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li v-else-if="item.feedback == 2" :key="index + 'x'">
								<div class="pic">
									<img :src="item.headPic" alt="" />
								</div>
								<div class="info">
									<div class="name">{{ item.username }}</div>
									<div class="desc" v-html="item.bugContent"></div>
									<div class="bottom">
										<div class="time">2024-5-28 9:54</div>
									</div>
								</div>
							</li>
						</template>
					</ul>
				</div>
			</div>
			<div class="rbox">
				<div class="g-tit1">
					<div class="i"></div>
					<div class="p">Bug详细描述</div>
				</div>
				<div class="scrollbox">
					<div class="row">
						<div class="lab">BugID：</div>
						<div class="desc">{{ info.bugNumber }}</div>
					</div>
					<div class="row">
						<div class="lab">创建时间：</div>
						<div class="desc">{{ info.createTime }}</div>
					</div>
					<div class="row">
						<div class="lab">Bug来源：</div>
						<div class="desc">{{ info.source }}</div>
					</div>
					<div class="row">
						<div class="lab">创建人：</div>
						<div class="desc">{{ info.userName }}</div>
					</div>
					<div class="row">
						<div class="lab">关联工单号：</div>
						<div class="desc">{{ info.outTradeNo }}</div>
					</div>
					<div class="row">
						<div class="lab">所属产品：</div>
						<div class="desc">{{ info.productName }}</div>
					</div>
					<div class="row">
						<div class="lab">所属模块</div>
						<div class="desc">{{ info.productModuleName }}</div>
					</div>

					<div class="row">
						<div class="lab">缺陷优先级：</div>
						<div class="severity">
							<i :style="{
				backgroundColor: priorityColor.get(info.priority)
			}"></i>
							<div class="p">{{ priority.get(info.priority) }}</div>
						</div>
					</div>
					<div class="row">
						<div class="lab">发现版本：</div>
						<div class="desc">{{ info.version }}</div>
					</div>
					<div class="row">
						<div class="lab">缺陷来源：</div>
						<div class="desc">{{ feedback[info.feedback] }}</div>
					</div>
					<div class="row">
						<div class="lab">验证版本：</div>
						<div class="desc">{{ info.verifVersion }}</div>
					</div>
					<div class="row">
						<div class="lab">修复版本：</div>
						<div class="desc">{{ info.fixVersion }}</div>
					</div>
					<div class="row">
						<div class="lab">处理人：</div>
						<div class="desc">{{ info.handle }}</div>
					</div>
					<div class="row">
						<div class="lab">开发人员：</div>
						<div class="desc">{{ info.open }}</div>
					</div>
					<div class="row">
						<div class="lab">测试人员：</div>
						<div class="desc">{{ info.test }}</div>
					</div>
					<div class="row">
						<div class="lab">迭代：</div>
						<div class="desc">{{ info.fixbug }}</div>
					</div>
					<div class="row">
						<div class="lab">发布计划：</div>
						<div class="desc">{{ info.plan }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 添加&文档沟通评论 -->
	<el-dialog :close-on-click-modal="false" title="添加Bug沟通评论" width="1100px" v-model="record.visible"
		@closed="(record.form = {}) && $refs.submitrecord.resetFields()">
		<div>
			<p class="col65 font14 padb10">
				根据研发工程师的反馈记录或者恢复 进行你的回复
			</p>
			<div class="h20"></div>
			<!-- 富文本编辑 -->
			<div class="m-editorb1">
				<div class="l">文档评论</div>
				<div class="r">
					<div class="editbox">
						<el-form :model="record.form" ref="submitrecord" label-width="auto" :rules="record.rules">
							<el-form-item label="" prop="bugContent">
								<div class="font14" style="
										width: 100%;
										box-sizing: border-box;
										z-index: 500;
									">
									<kindeditor :content="record.form.bugContent" width="700px" height="400px"
										:uploadParams="{
				type: 5,
				id: $route.params.id
			}" @base64Html="base64Flie($event, 'record', 'bugContent', 'form')" id="kind2"
										@on-content-change="onContentChange($event, 'record', 'bugContent', 'form')">
									</kindeditor>
									<!-- <Toolbar style="border-bottom: 1px solid #dfe2e8" :editor="editorRef" mode="default" />
									<Editor style="height: 400px; overflow-y: hidden" v-model="record.form.bugContent"
										:defaultConfig="{ placeholder: '请输入内容...' }" mode="default"
										@onCreated="editorRef = $event" /> -->
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div class="h40"></div>
					<el-button :loading="record.loading" @click="addBugCommunicationReview" size="large"
						type="primary">提交反馈</el-button>
				</div>
			</div>
			<div class="h30"></div>
		</div>
	</el-dialog>
	<!-- 添加&文档沟通评论end -->
</template>
<script>
import OSS from 'ali-oss'
import { uploadOssGetToken } from "@/utils/api1.js"
// import { msgDetail, bindingAcount } from "@/utils/api1.js"
import { shallowRef } from 'vue'
import { Back } from '@element-plus/icons-vue'
import '@wangeditor/editor/dist/css/style.css'
export default {
	setup() {
		return {
			bugStatus: new Map([
				[1, '待审核'],
				[2, '审核通过'],
				[3, '拒绝'],
				[4, '新'],
				[5, '接受/处理'],
				[6, '已解决'],
				[7, '已验证'],
				[8, '已关闭'],
				[9, '重新打开'],
				[10, '已拒绝'],
			]),

			priority: new Map([
				[1, '致命'],
				[2, '严重'],
				[3, '一般'],
				[4, '提示'],
				[5, '建议'],
			]),
			priorityColor: new Map([
				[1, '#e1504a'],
				[2, '#ff9006'],
				[3, '#06b0ff'],
				[4, '#32e6aa'],
				[5, '#32e6aa'],
			]),
			feedback: { 1: '工程师', 2: '用户', 3: 'tapd测试工程师' },
		}
	},
	components: { Back },
	computed: {
		prevUpdateTime() {
			const _list = this.record.list.map(item => new Date(item.createTime)).sort((a, b) => a - b)
			const _max = new Date(Math.max(..._list))
			if (this.record.loading) {
				return ``
			}
			if (_list.length) { // 如果有沟通记录
				return `${_max.getFullYear()}-${_max.getMonth() + 1}-${_max.getDate()} ${_max.getHours()}:${_max.getMinutes()}:${_max.getSeconds()}`
			}
			return this.info.createTime
		},
		attachmentPath() {
			if (this.info.attachmentPath) {
				return this.info.attachmentPath.split(',')
			}
			return []
		}
	},
	data() {
		return {
			mode: 'default',
			loading: false,
			info: {},
			priorityL: { 1: '致命', 2: '严重', 3: '一般', 4: '提示', 5: '建议' },
			priorityC: { 1: '#E1504A', 2: '#FF6A00', 3: '#06B0FF', 4: '#49E8B2', 5: '#49E8B2', },
			feedbackL: { 1: '工程师', 2: '用户', 3: 'tapd测试工程师' },
			// 沟通记录
			record: {
				loading: false,
				param: {
					bugId: this.$route.params.id,
					pageNumber: 1,
					pageSize: 10,
				},
				list: [],
				total: 0,
				visible: false,
				form: {

				},
				rules: {
					bugContent: [{ message: '请输入文档内容！', required: true }],
				},
				submitLoading: false,
			},
		}
	},
	mounted() {
		this.getData()
	},
	beforeUnmount() {
	},
	methods: {
		clickXz(url, title) {//下载文件
			if (url) {
				var xhr = new XMLHttpRequest()
				xhr.open('GET', url, true)
				xhr.responseType = 'blob';
				xhr.onload = function (e) {
					//如果请求执行成功
					if (this.status == 200) {
						var blob = this.response;
						var filename = title;//如123.xls
						var a = document.createElement('a');
						//创键临时url对象
						var url = URL.createObjectURL(blob);

						a.href = url;
						a.download = filename;
						a.click();
						//释放之前创建的URL对象
						window.URL.revokeObjectURL(url);
					}
				};
				//发送请求
				xhr.send();
			}
		},
		base64Flie(base64, key1, key2, key3) {
			if (base64) {
				if (key3) {
					this[key1][key3][key2] += base64
				} else {
					this[key1][key2] += base64
				}
			}

		},
		onContentChange(val, key1, key2, key3) {
			let that = this
            if (val.includes(';base64')) {
                let start = `<img src="data:`
				let end = `" alt="" />`
				const regex = new RegExp(`${start}(.*?)${end}`);
				const result = regex.exec(val);
                const substr = result ? ('data:'+result[1]) : ""; 
				console.log('截取',substr);
				uploadOssGetToken().then(res1 => {
					console.log('获取oss参数',res1)
					if (res1) {
						that.uploadToOSS(res1, substr, key1, key2, key3,val.split(substr)[0],val.split(substr)[1])
					}
				});
			}else if (key3) {
				this[key1][key3][key2] = val
			} else {
				this[key1][key2] = val
			}
		},
		
		async uploadToOSS(data, url, key1, key2, key3,p1,p2) {
			let that = this
			console.log('获取图片路径', url)
			function dataURLtoBlob(dataurl) {
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new Blob([u8arr], { type: mime });
			}
			const blob = dataURLtoBlob(url)
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const filePath = `uploads${year}${month}${day}`;
			let file = new File([blob], filePath, { type: blob.type });
			console.log('filefilefile', file)
			let client = new OSS({
				region: 'oss-cn-beijing',
				secure: true,
				accessKeyId: data.ossAccessKeyId,
				accessKeySecret: data.accessKeySecret,
				stsToken: data.securityToken,
				endpoint: "oss-cn-beijing.aliyuncs.com",
				bucket: 'bucket-mvs'
			});
			try {
				console.log('文件', file)
				//上传
				let result = await client.multipartUpload(Date.parse(new Date()) / 1000 + filePath, file, {});
				console.log('上传成功返回', result)
				let url = p1 + result.res.requestUrls[0].split('?uploadId=')[0] + p2
				if (key3) {
					that[key1][key3][key2] = url
				} else {
					that[key1][key2] = url
				}
				// that.file.url = result.res.requestUrls[0]
				// that.fileList.attachment['attachmentPath'] = result.res.requestUrls[0]
			} catch (e) {
				console.log('失败', e)
				if (e.code === 'ConnectionTimeoutError') {
					content.onError('视频上传超时')
					throw "视频上传超时!";
				} else {
					content.onError('视频上传失败')
				}
			}
		},
		async bugCommunicationRecord(pageNumber) {
			try {
				pageNumber && (this.record.param.pageNumber = pageNumber)
				this.record.loading = true
				const { records, total } = await this.$api.bug.bugCommunicationRecord(this.record.param)
				this.record.list = records
				this.record.total = total
			} catch (e) {
				return console.log(e)
			} finally {
				this.record.loading = false
			}
		},
		async clickDeletes(id) {
			await this.$confirm(
				"确认该反馈删除吗",
				"信息",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
				}
			)
			const res = await this.$api.bug.BugFeedbackRemoveCommunicationRecord({ id })
			if (res) {
				this.$message.success(res.message);
				this.bugCommunicationRecord(1)
			}
		},
		async clickDelete() {
			console.log(this.info)
			await this.$confirm(
				`确认删除"${this.info.bugTitle}"吗`,
				"信息",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
				}
			)
			const result = await this.$api.bug.BugFeedbackRemoveBug({
				id: this.info.id,
			})
			if (result) {
				this.$router.back()
			}
		},
		async addBugCommunicationReview() {
			try {
				await this.$refs['submitrecord'].validate()
				this.record.loading = true
				let res = await this.$api.bug.BugFeedbackAddBugCommunicationReview({
					bugId: this.record.param.bugId,
					id: this.record.form.id || ''
				}, { bugContent: this.record.form.bugContent })
				if (res) {
					this.$message.success(res.message)
					this.record.visible = false
					this.bugCommunicationRecord(1)
				}
			} catch (e) {
				return console.log(e)
			} finally {
				this.record.loading = false
			}
		},
		async getData() {
			try {
				this.loading = true
				this.info = await this.$api.bug.bugdetails({ id: this.$route.params.id })
				this.record.loading = true
				const { records, total } = await this.$api.bug.bugCommunicationRecord(this.record.param)
				this.record.list = records
				this.record.total = total
				this.record.loading = true
			} catch (e) {
				return console.log(e)
			} finally {
				this.loading = false
				this.record.loading = false
			}
		},
	}
}
</script>
<style lang="scss" scoped>
.breake {
	display: flex;
	align-items: center;

	i {
		display: block;
		width: 15px;
		height: 13px;
		background-image: url(../../assets/imgs/breake.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-right: 15px;
	}
}

.g-tit1 {
	display: flex;
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e9eb;
	margin-bottom: 20px;

	.i {
		display: block;
		width: 3px;
		height: 14px;
		background-color: #ff6a00;
		margin-right: 9px;
	}

	.p {
		font-size: 14px;
		color: #313133;
	}
}

.g-tit2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 18px;
	border-bottom: 1px solid #e8e9eb;

	.l {
		display: flex;
		align-items: center;

		.i {
			display: block;
			width: 3px;
			height: 14px;
			background-color: #ff6a00;
			margin-right: 9px;
		}

		.tit {
			font-size: 14px;
			color: #313133;
			margin-right: 20px;
		}

		.add {
			display: block;
			padding: 0 17px;
			background-color: #ff6a00;
			font-size: 12px;
			line-height: 31px;
			color: #fff;
			cursor: pointer;
		}
	}

	.r {
		display: flex;
		align-items: center;

		.lab {
			font-size: 12px;
			color: #242424;
		}

		.sel {
			width: 150px;
		}
	}
}

.jl-about1 {
	padding: 20px 0;

	.titbox {
		background-color: #fff;
		margin-bottom: 20px;
		padding: 20px;

		.topbox {
			display: flex;
			margin-bottom: 15px;

			.tit {
				flex: 1;
				font-size: 14px;
				font-weight: 500;
				color: #242424;
				line-height: 22px;
				overflow: hidden;
			}

			.upDate {
				display: flex;
				align-items: center;
				flex-shrink: 0;

				.p {
					font-size: 12px;
					color: #999999;
					margin-right: 20px;
				}

				.i {
					display: block;
					width: 30px;
					height: 30px;
					font-size: 0;
					line-height: 30px;
					border: 1px solid #dddddd;
					text-align: center;
					cursor: pointer;

					img {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.descbox {
			display: flex;
			align-items: center;

			.tag {
				flex: 1;
				overflow: hidden;
				display: flex;
				align-items: center;

				.con {
					display: flex;
					align-items: center;
					font-size: 12px;
					line-height: 16px;
					margin-right: 50px;
					color: #222222;

					.lab {
						color: #b1b1b1;
						margin-right: 8px;
					}

					.type {
						color: #0091ea;
					}

					.severity {
						display: flex;
						align-items: center;

						i {
							display: block;
							width: 6px;
							height: 6px;
							border-radius: 50%;
							margin-right: 4px;
						}
					}
				}
			}

			.operation {
				flex-shrink: 0;
				display: flex;

				.btn {
					display: flex;
					align-items: center;
					padding: 0 10px;
					height: 30px;
					color: #fff;
					cursor: pointer;

					.i {
						font-size: 0;
						margin-right: 4px;
					}

					.p {
						font-size: 12px;
					}
				}

				.nopass {
					background-color: #e1504a;
					margin-right: 20px;
				}

				.pass {
					background-color: #ff6a00;
				}
			}
		}
	}

	.infobox {
		display: flex;
		align-items: flex-start;

		.lbox {
			flex: 1;

			.box1 {
				background-color: #fff;
				padding: 24px 20px 20px;
				margin-bottom: 20px;

				.scrollbox {
					max-height: 570px;
					overflow-y: auto;

					.desc {
						max-width: 830px;
						font-size: 14px;
						color: #151515;
						line-height: 2;
						margin-bottom: 20px;

						a {
							color: #1a9dc1;
						}
					}

					.copybox {
						position: relative;
						background-color: #50566b;
						padding: 15px 20px;
						font-size: 14px;
						color: #fff;
						line-height: 2;
						max-width: 712px;

						.icn {
							position: absolute;
							top: 10px;
							right: 10px;
						}
					}
				}
			}

			.box2 {
				padding: 18px 20px 0;
				background-color: #fff;

				.ul-list {
					li:last-child {
						border: none;
					}

					li {
						padding: 20px 0;
						border-bottom: 1px solid #e8e9eb;
						overflow: hidden;

						.pic {
							float: left;
							width: 40px;
							height: 40px;
							font-size: 0;
							border-radius: 50%;
							margin-right: 15px;
							overflow: hidden;

							img {
								display: block;
								width: 100%;
							}
						}

						.info {
							overflow: hidden;

							.name {
								font-size: 12px;
								line-height: 17px;
								color: #1f1f1f;
								margin-bottom: 14px;
							}

							.desc {
								font-size: 12px;
								line-height: 20px;
								color: #222222;
								margin-bottom: 20px;
							}

							.bottom {
								display: flex;
								align-items: center;
								justify-content: space-between;

								.time {
									font-size: 12px;
									color: #999999;
								}

								.btns {
									display: flex;
									align-items: center;

									.btn {
										display: flex;
										align-items: center;
										margin-left: 30px;
										cursor: pointer;

										.i {
											display: block;
											font-size: 0;
											margin-right: 5px;
										}

										.p {
											font-size: 12px;
											color: #999999;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.rbox {
			flex-shrink: 0;
			width: 334px;
			margin-left: 20px;
			background-color: #fff;
			padding: 24px 20px 20px;

			.scrollbox {
				max-height: 620px;
				overflow-y: auto;

				.row {
					display: flex;
					margin-bottom: 18px;
					font-size: 12px;
					color: #222222;
					line-height: 17px;

					.lab {
						flex-shrink: 0;
						width: 97px;
						color: #9a9a9a;
					}

					.desc {
						flex: 1;
						overflow: hidden;
					}

					.severity {
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;

						i {
							flex-shrink: 0;
							display: block;
							width: 6px;
							height: 6px;
							background-color: #e1504a;
							border-radius: 50%;
							margin-right: 5px;
						}
					}
				}

				.row:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>